var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Magnitude, MAGNITUDES, MaterialAmount, PreferredUnits, WEIGHT } from "./MaterialAmount";
import uniqby from 'lodash.uniqby';
import isEqual from 'lodash.isequal';
import { MASS_BALANCE } from "../transactions";
import { Decimal } from "decimal.js";
/**
 * Represents a "Material type with all of its possible attribute
 * configurations"
 *
 * This is the kind of data structure that powers the Materials'
 * configuration on Material Settings.
 */
export class MaterialFamily {
    /**
     *
     * @param {String} name
     * @param {Magnitude} magnitude
     * @param {Array<MaterialAttribute>} attributes
     */
    constructor(name, magnitude, attributes = []) {
        /**
         * We're calling the names of Materials `materialName` instead of
         * just `name`. Which makes no sense because it's already namespaced
         * within a MaterialFamily.
         *
         * We're using that subpar name on the frontend and backend.
         *
         * TODO: rename `materialName` to just `name` and simplify this class.
         */
        this.materialName = '';
        this.magnitude = WEIGHT;
        /**
         * Attributes would be a more apt name than Categories, in the context
         * of Materials. But that's the wording we're using in frontend and backend.
         * TODO: rename to `attributes`
         */
        this.categories = [];
        this.toString = () => {
            return this.name;
        };
        this.equals = (other) => {
            const sameType = other instanceof MaterialFamily;
            const sameMag = this.magnitude.equals(other.magnitude);
            const sameName = this.name == other.name;
            return sameType && sameMag && sameName;
        };
        /**
         * Merges 2 MaterialFamily instances if possible. Otherwise returns
         * the original MaterialFamily.
         *
         * MaterialFamily instances can be merged when they represent the same
         * material. IE: their name and magnitude are the same.
         *
         * Merging MaterialFamily instances involves merging their attributes.
         *
         * @param {MaterialFamily} other
         *
         * @returns {MaterialFamily}
         */
        this.merge = (other) => {
            if (!(other instanceof MaterialFamily)) {
                throw TypeError(`Expected type MaterialFamily. Got ${other.constructor.name}`);
            }
            if (this.id != other.id) {
                /**
                 * The instances refer to differnt Material Families,
                 * so it doesn't make sense to merge.
                 */
                return this;
            }
            const allAttrs = this.attributes.concat(other.attributes);
            const mergedAttrs = allAttrs.map(el => allAttrs.reduce((reduced, toReduce) => reduced.merge(toReduce), el));
            const deduplicatedAttrs = uniqby(mergedAttrs, el => el.name);
            return new MaterialFamily(this.name, this.magnitude, deduplicatedAttrs);
        };
        this.name = name;
        if (!(magnitude instanceof Magnitude)) {
            throw new TypeError(`Expected Magnitude. Got ${magnitude.constructor.name}`);
        }
        this.magnitude = magnitude;
        const validCategories = (attributes instanceof Array)
            && attributes.every(el => el instanceof MaterialAttribute);
        if (!validCategories) {
            throw new TypeError(`Expected Array of MaterialAttribute. Got ${attributes.constructor.name}`);
        }
        this.attributes = attributes;
    }
    /**
     * This getter is in place for legacy reasons. The existing
     * code seems to be looking for a 'materialName' prop, instead of
     * plain 'name'. Both on frontend and backend
     *
     * TODO: get rid of the references to materialName, so we can
     * remove this getter.
     */
    get name() {
        return this.materialName;
    }
    set name(val) {
        this.materialName = val;
    }
    get id() {
        return `${this.name}-${this.magnitude}`;
    }
    /**
     * These getter/setter are in place for legacy reasons. The existing
     * code seems to be looking for a 'categories' prop, where a more
     * apt name is 'attributes'. Both on frontend and backend
     *
     * TODO: get rid of the references to categories, so we can
     * remove this.
     */
    get attributes() {
        return this.categories;
    }
    set attributes(attrs) {
        this.categories = attrs;
    }
    /** @returns {MaterialAttribute[]} **/
    getAttributes() {
        return this.categories;
    }
    /**
     * @param {String} name
     * @returns {MaterialAttribute}
     */
    getAttribute(name) {
        return this.categories.find(attr => {
            return attr['itemValue'] === name;
        });
    }
    /**
     * @param {String} name
     * @returns Boolean
     */
    hasAttributeName(name) {
        const attributes = this.getAttributes();
        return attributes.some(attr => {
            return attr['itemValue'] === name;
        });
    }
    static fromJson({ materialName: name, magnitude, categories: attributes }) {
        const cats = attributes.map(el => MaterialAttribute.fromJson(el));
        const mag = MAGNITUDES.get(magnitude);
        return new MaterialFamily(name, mag, cats);
    }
    /**
     * Extracts the potential MaterialFamily of a MassBalance.
     *
     * That is, a MaterialFamily instance that has the same material
     * name, magnitude and attributes than the MassBalance.
     *
     * @param {MassBalance} massBalance
     *
     * @returns {MaterialFamily}
     */
    static fromMassBalance(massBalance) {
        const attrs = massBalance.material.attributes
            .map(el => new MaterialAttribute(el.name, [el.value]));
        return new MaterialFamily(massBalance.material.name, massBalance.material.magnitude, attrs);
    }
    /**
     * Merges two Array<MaterialFamily> together.
     *
     * See MaterialFamily.merge for context on merging
     * MaterialFamily instances.
     *
     * @param {Array<MaterialFamily>} families1
     * @param {Array<MaterialFamily>} families2
     * @returns {Array<MaterialFamily>}
     */
    static mergeFamilies(families1, families2) {
        const familiesAreArrays = families1 instanceof Array
            && families2 instanceof Array;
        const typesAreCorrect = families1.every(el => el instanceof MaterialFamily)
            && families2.every(el => el instanceof MaterialFamily);
        if (!familiesAreArrays || !typesAreCorrect) {
            throw new TypeError(`Expected both familiies to be Array<MaterialFamily>`);
        }
        const all = families1.concat(families2);
        const merged = all.map(el => all.reduce((reduced, toReduce) => reduced.merge(toReduce), el));
        const deduplicated = uniqby(merged, el => el.id);
        return deduplicated;
    }
}
/**
 * Represents an Attribute of a Material, with all its
 * potential Values.
 *
 * IE: The attribute "Color" with its potential values "Red",
 * "Green", "Yellow"
 */
export class MaterialAttribute {
    /**
     * Trimming of strings implemented due to data corruption issue,
     * further discussion:
     * https://github.com/EmpowerPlastic/empower-platform/issues/2335
     * @param {String} name
     * @param {Array<String>} values
     */
    constructor(name, values = []) {
        /**
         * itemValue is a terrible name for what's literally the
         * MaterialAttribute's name. But that's the name we're using on
         * the frontend and backend.
         *
         * TODO: eventually rename to just `name`
         */
        this.itemValue = '';
        this.values = [];
        /**
         *
         * @param {Any} other
         * @returns {Boolean}
         */
        this.equals = (other) => {
            const sameType = other instanceof MaterialAttribute;
            const sameName = other.name == this.name;
            return sameType && sameName;
        };
        /**
         * Merges two MaterialAttribute instances, if possible.
         *
         * Merging them involves creating a new instance whose
         * values are the combination of the ones on
         * the merged instances
         *
         * Merging two MaterialAttributes is only possible if
         * they refer to the same attribute (ie: both refer to
         * Color)
         *
         * @param {MaterialAttribute} other
         * @returns {MaterialAttribute}
         */
        this.merge = (other) => {
            if (!(other instanceof MaterialAttribute)) {
                throw TypeError("can only merge with other MaterialAttribute instances");
            }
            if (this.name != other.name) {
                /**
                 * The instances refer to different Attributes so
                 * it doesn't make sense to merge them (IE: Color and Size)
                 */
                return this;
            }
            const values = Array.from(
            // Set will keep the unique attrs only
            new Set(this.values.concat(other.values)));
            return new MaterialAttribute(this.name, values);
        };
        this.name = name.trim().toLowerCase();
        const valuesAreValid = values instanceof Array
            && values.every(el => typeof el == "string");
        if (!valuesAreValid) {
            throw new TypeError(`Expected values to be Array<String>. Got ${values}`);
        }
        const trimmedValues = values.map(el => el.trim());
        this.values = trimmedValues;
    }
    /**
     * name is put in place for legacy reasons. The existing
     * code is looking for a prop called 'itemValue' instead of
     * plain 'name'. Both on the frontend and backend apparently
     *
     * TODO: get rid of all the references to 'itemValue' and rename
     * to `name` so we can remove this getter.
     */
    get name() {
        return this.itemValue;
    }
    set name(val) {
        this.itemValue = val;
    }
    /**
     * Parses Payloads sent from the backend into
     * MaterialAttribute instances.
     *
     * The kind of bcakend payload this would parse
     * are the ones that power the attribute configuration
     * of Material Settings
     * @param {*} param0
     * @returns
     */
    static fromJson({ itemValue: name, values }) {
        return new MaterialAttribute(name, values);
    }
}
/**
 * SelectedMaterialAttribute represents the value of a MaterialAttribute
 * that has actually been selected. IE: color=red
 */
export class SelectedMaterialAttribute {
    /**
     *
     * @param {String} name
     * @param {String} value
     */
    constructor(name, value) {
        this.name = "";
        this.value = "";
        this.toJson = () => ({ [this.name]: this.value });
        this.equals = (other) => {
            return this.name == other.name && this.value == other.value;
        };
        this.name = name.trim().toLowerCase();
        this.value = value.trim();
    }
}
/**
 * Checks if two lists of attributes are equal
 * Ignores order of list and wether objects in the list are true SelectedMaterialAttribute objects
 * @param {SelectedMaterialAttribute[]} list1
 * @param {SelectedMaterialAttribute[]} list2
 */
export function equalListOfAttributes(list1, list2) {
    if (list1.length != list2.length) {
        return false;
    }
    return list1.every(el => list2.some(el2 => el.equals(el2)));
}
/**
 * On the backend, Materials have an associated massBalanceKey (poor name),
 * that is derived from their name, magnitude and attributes.
 */
export class Material {
    constructor(name, magnitude, attributes, massBalanceKey = undefined) {
        /**
         * toString produces a human-friendly short representation of
         * the Material.
         *
         * @returns
         */
        this.toString = () => {
            return this.name;
        };
        /**
         * Check material equality
         * @param {Material} other
         * @returns {boolean}
         */
        this.equals = (other) => {
            if (!other instanceof Material)
                return false;
            return other.name === this.name &&
                this.magnitude.equals(other.magnitude) &&
                equalListOfAttributes(this.attributes, other.attributes);
        };
        this.equalsWithoutSerialNo = (other) => {
            if (!other instanceof Material)
                return false;
            return other.name === this.name &&
                this.magnitude.equals(other.magnitude) &&
                equalListOfAttributes(this.attributes, other.attributes.filter(a => a.name !== 'serial-number'));
        };
        /** @type {string} **/
        this.name = name;
        /** @type {Magnitude} **/
        this.magnitude = magnitude;
        /** @type {SelectedMaterialAttribute[]} **/
        this.attributes = attributes;
        /** @type {string|null} **/
        this.massBalanceKey = massBalanceKey;
    }
    get massBalanceDefinition() {
        const mb = {
            plasticType: this.name,
        };
        this.attributes.forEach(el => {
            mb[el.name] = el.value;
        });
        return mb;
    }
    /**
     *
     * @param {Material} material
     */
    areMassBalanceDefinitionsEqual(material) {
        const firstMbDef = this.massBalanceDefinition;
        const secondMbDef = material.massBalanceDefinition;
        return isEqual(firstMbDef, secondMbDef);
    }
    /**
     * Needed for equality check in tracking app.
     * When we stop using opaque "massBalanceDefinitions" and migrate
     * them all to Materials, then we just use the better
     * 'areMassBalanceDefinitionsEqual' above
     * @param massBalanceDefinition
     */
    isMaterialDefEqualToOpaqueMassBalanceDef(massBalanceDefinition) {
        const firstMbDef = this.massBalanceDefinition;
        return isEqual(firstMbDef, massBalanceDefinition);
    }
    /** Get attribute with given name
     * @param {String} attributeName
     * @returns {SelectedMaterialAttribute} attribute
     */
    getAttribute(attributeName) {
        return this.attributes.find(attr => attr.name === attributeName);
    }
    /** Adds attribute to the list of attributes
     * @param {SelectedMaterialAttribute} attribute
     */
    addAttribute(attribute) {
        this.attributes.push(attribute);
    }
    /** Checks if the list of attributes contains the given attribute
     * @param {SelectedMaterialAttribute} attribute
     */
    hasAttribute(attribute) {
        return this.attributes.some(attr => attr.equals(attribute));
    }
    /** Removes attribute with given name from the list of attributes
     * @param {String} attributeName
     */
    removeAttribute(attributeName) {
        this.attributes = this.attributes.filter(attr => attr.name !== attributeName);
    }
    static fromJson({ magnitude, massBalanceDefinition, massBalanceKey }) {
        const { plasticType: name } = massBalanceDefinition, attrs = __rest(massBalanceDefinition, ["plasticType"]);
        const attributes = Object.entries(attrs)
            .map(([k, v]) => new SelectedMaterialAttribute(k, v));
        const mag = MAGNITUDES.get(magnitude);
        return new Material(name, mag, attributes, massBalanceKey);
    }
    /**
     * Clones a Material
     * @returns {Material}
     */
    clone() {
        const clonedAttributes = this.attributes.map(attr => new SelectedMaterialAttribute(attr.name, attr.value));
        return new Material(this.name, new Magnitude(this.magnitude), clonedAttributes, this.massBalanceKey);
    }
}
export class InvalidCurrency extends Error {
}
export class Payment {
    constructor(amount, pricePerUnit, originalAmount = undefined, originalPricePerUnit = undefined) {
        /**
         * @param {Payment|undefined} other
         **/
        this.plus = (other) => {
            /** If other is undefined, strip price per unit while retaining amount for totalPayment calculation **/
            if (!other) {
                return new Payment(this.amount, undefined, this.originalAmount, undefined);
            }
            return new Payment(+this.amount + +other.amount, this.pricePerUnit === other.pricePerUnit ? this.pricePerUnit : undefined, (!this.originalAmount && !other.originalAmount) ? undefined :
                (this.originalAmount || this.amount) + (other.originalAmount || other.amount));
        };
        this.clone = () => {
            return new Payment(this.amount, this.pricePerUnit, this.originalAmount, this.originalPricePerUnit);
        };
        /**
         * @param {Payment} other
         * @returns {true|false} true if the two payments are equal
         */
        this.equals = (other) => {
            return !!other &&
                this.amount === other.amount &&
                this.pricePerUnit === other.pricePerUnit &&
                this.originalAmount === other.originalAmount;
        };
        /**
         * Check if another payment instance has same unit price as this
         * @param {Payment} other
         * @returns {boolean} true if pricePerUnit is equal
         */
        this.sameUnitPrice = (other) => {
            return other != null &&
                this.pricePerUnit === other.pricePerUnit;
        };
        this.toString = (currency) => {
            return `${this.amount} ${currency} (${this.pricePerUnit}/kg)`;
        };
        this.toJson = () => {
            return Object.assign({ amount: this.amount }, (this.pricePerUnit && { pricePerUnit: this.pricePerUnit }));
        };
        /** @type {number} **/
        this.amount = amount;
        /** @type {number} **/
        this.pricePerUnit = pricePerUnit;
        /** @type {number | undefined} **/
        this.originalAmount = originalAmount;
        /** @type {number | undefined} **/
        this.originalPricePerUnit = originalPricePerUnit;
    }
    get printableAmount() {
        return new Decimal(this.amount).toFixed(2);
    }
    /**
     * Check if two payments have the same unit price
     * @param {Payment} payment1
     * @param {Payment} payment2
     * @returns {boolean} true if pricePerUnit is equal or both payments are null, false otherwise
     */
    static sameUnitPrice(payment1, payment2) {
        return !!payment1 ? payment1.sameUnitPrice(payment2) : !payment2;
    }
}
/**
 * MassBalance represents an Amount of a Material. Example:
 * "10 kilograms of PET", or "10 plastic bottles".
 *
 * So it can be used as
 *
 *   * inventory entries ("we have 10kg of PET"),
 *
 *   * inputs/outputs of transactions ("we are trasforming 10kg of PET",
 *     "We are transforming X into 20 cubic meters of oil", "we are
 *     delivering 20kg of glass", etc).
 */
export class MassBalance {
    /**
     * Creates a MassBalance instance.
     * @param {Material} material
     * @param {MaterialAmount} amount
     * @param {Payment} payment (optional)
     * @param {MaterialAmount} originalAmount (optional)
     */
    constructor(material, amount, payment = undefined, originalAmount = undefined) {
        if (!(material instanceof Material)) {
            throw new TypeError(`Expected type Material. Got ${material.constructor.name}`);
        }
        /** @type {Material} **/
        this.material = material;
        if (!(amount instanceof MaterialAmount)) {
            throw new TypeError(`Expected type MaterialAmount. Got ${amount.constructor.name}`);
        }
        /** @type {MaterialAmount} **/
        this.amount = amount;
        if (payment && !(payment instanceof Payment)) {
            throw new TypeError(`Expected type Payment. Got ${payment.constructor.name}`);
        }
        /** @type {Payment | undefined} **/
        this.payment = payment;
        if (originalAmount && !(originalAmount instanceof MaterialAmount)) {
            throw new TypeError(`Expected type MaterialAmount. Got ${originalAmount.constructor.name}`);
        }
        /** @type {MaterialAmount | undefined} **/
        this.originalAmount = originalAmount;
    }
    /**
     * TODO: remove eventually
     *
     * processAmount works around the fact that we were abusing
     * the `processAmount` of inventory items to store the "chunks
     * of the inventory items selected for processing".
     *
     * We're removing that abuse, so this will become irrelevant soon.
     * For the time being it's left here so it doesn't break the
     * codebase.
     *
     * @returns {MaterialAmount}
     */
    get processAmount() {
        return this.amount;
    }
    /**
     * @returns {Magnitude}
     */
    get magnitude() {
        return this.material.magnitude;
    }
    /**
     * @returns {Object}
     */
    get massBalanceDefinition() {
        return this.material.massBalanceDefinition;
    }
    /**
     * @returns {String}
     */
    get massBalanceKey() {
        return this.material.massBalanceKey;
    }
    /**
     * @param {String} name
     * @returns {SelectedMaterialAttribute}
     */
    getAttribute(name) {
        return this.material.attributes.find(attr => {
            return attr.name === name;
        });
    }
    /**
     * @param {SelectedMaterialAttribute} attrToRemove
     * @returns {Array<SelectedMaterialAttribute>}
     */
    removeAttrFromAttributes(attrToRemove) {
        return this.material.attributes.filter(attr => {
            return attr !== attrToRemove;
        });
    }
    /**
     * Creates a MassBalance that carries a zero-amount
     * of the Material of the current MassBalance.
     *
     * IE: I have a MassBalance of "10 bottles", if I
     * call this method on it, I get a new MassBalance
     * of "0 bottles"
     *
     * This is useful to do processing on the MassBalance.
     * IE: If I have 10kg of PET and I want to process some
     * of it, I can start by creating an empty balance from it,
     * and later setting its amount.
     *
     * This intends to prevent the mutations we had been doing
     * on the entries of `store.inventory`
     *
     * @returns {MassBalance}
     */
    createEmpty() {
        return new MassBalance(this.material, new MaterialAmount(0, this.amount.magnitude, this.amount.preferredUnits));
    }
    /**
     * Clones a MassBalance.
     * Current use case: Generating a Reception Manifest with
     * cloned Delivery Manifest Inventory Items
     * @returns {MassBalance}
     */
    clone() {
        const clonedMaterial = this.material.clone();
        const clonedAmount = this.amount.clone();
        const clonedOriginalAmount = this.originalAmount ? this.originalAmount.clone() : undefined;
        const clonedPayment = this.payment ? this.payment.clone() : undefined;
        return new MassBalance(clonedMaterial, clonedAmount, clonedPayment, clonedOriginalAmount);
    }
    /** Check if the values of this object are equal to other's
     * @param {MassBalance} other
     * @return {boolean} true if equal
     */
    equals(other) {
        return !!other &&
            other instanceof MassBalance &&
            this.material.equals(other.material) &&
            this.amount.equals(other.amount) &&
            (!!this.originalAmount ? this.originalAmount.equals(other.originalAmount) : !other.originalAmount) &&
            (!!this.payment ? this.payment.equals(other.payment) : !other.payment);
    }
    toJson() {
        return Object.assign(Object.assign({ massBalanceDefinition: this.massBalanceDefinition, amount: this.amount.toNumber(), magnitude: this.amount.magnitude.toString() }, (this.payment && { payment: this.payment.toJson() })), { type: MASS_BALANCE.toString() });
    }
    /**
     * @param {Object} param0
     * @param {PreferredUnits} prefUnits
     *
     * @returns {MassBalance}
     */
    static fromJson(_a, prefUnits) {
        var { amount } = _a, rest = __rest(_a, ["amount"]);
        const material = Material.fromJson(rest);
        const am = new MaterialAmount(amount, material.magnitude, prefUnits);
        return new MassBalance(material, am);
    }
    /**
     *
     * @param {Object} massBalanceObj
     * @param {PreferredUnits} preferredUnits
     * @returns {MassBalance}
     */
    static fromTransaction(massBalanceObj, preferredUnits) {
        return new MassBalance(new Material(massBalanceObj.massBalanceDefinition["plasticType"], massBalanceObj.magnitude, Object.entries(massBalanceObj.massBalanceDefinition)
            .filter(entry => entry[0] !== "plasticType")
            .map(entry => new SelectedMaterialAttribute(entry[0], entry[1]))), new MaterialAmount(massBalanceObj.amount, massBalanceObj.magnitude, preferredUnits), massBalanceObj.payment ?
            new Payment(massBalanceObj.payment.amount, massBalanceObj.payment.pricePerUnit) :
            null);
    }
}
export class DigitalTwins {
    /**
     * @param {string} name
     * @param {number} amount
     * @param {Date} createdTime
     * @param {Array<MassBalance>} inventory
     * @param {string|undefined} balanceId // provided by backend, not present in FE for example in Process flow.
     * @param {string|undefined} gtin
     * @param {string|undefined} lotCode
     * @param {Payment|undefined} payment
     */
    constructor(name, amount, createdTime, inventory, balanceId = undefined, gtin = undefined, lotCode = undefined, payment = undefined) {
        this.name = name;
        this.amount = amount;
        this.createdTime = createdTime;
        inventory.forEach(el => {
            if (!el instanceof MassBalance) {
                throw new TypeError(`Expected inventory holding MassBalances. Got ${amount.constructor.name}`);
            }
        });
        /** @type {Array<MassBalance>} **/
        this.inventory = inventory;
        this.balanceId = balanceId;
        this.gtin = gtin;
        this.lotCode = lotCode;
        this.payment = payment;
    }
    /**
     * @returns {DigitalTwins}
     */
    clone() {
        const dtInventory = this.inventory.map(massBalance => {
            return massBalance.clone();
        });
        return new DigitalTwins(this.name, this.amount, this.createdTime, dtInventory, this.balanceId, this.gtin, this.lotCode, this.payment);
    }
    static fromJson(id, name, amount, createdTime, inventory, gtin, lotCode, preferredUnits) {
        return new DigitalTwins(name, amount, createdTime, Object.values(inventory).map(mbJson => MassBalance.fromJson(mbJson, preferredUnits)), id, gtin, lotCode);
    }
    /**
     * Converts a Firestore Timestamp to a Unix timestamp in milliseconds.
     *
     * @param {Object} timestamp - The Firestore Timestamp object.
     * @param {number} timestamp.seconds - The number of seconds since Unix epoch.
     * @param {number} timestamp.nanoseconds - The number of nanoseconds after the second.
     * @returns {number} The Unix timestamp in milliseconds.
     */
    static convertTimestampToUnix(timestamp) {
        // Convert seconds to milliseconds and add nanoseconds converted to milliseconds
        return (timestamp.seconds * 1000) + Math.floor(timestamp.nanoseconds / 1000000);
    }
    toBalanceJson() {
        return Object.assign({ type: 'DIGITAL_TWIN_BALANCE', digitalTwinsBalanceId: this.balanceId, numberOfDigitalTwins: this.amount }, (this.payment && { payment: this.payment.toJson() }));
    }
    toJson() {
        return Object.assign({ type: "DIGITAL_TWIN", numberOfDigitalTwins: this.amount, digitalTwinsName: this.name, digitalTwinsBalanceId: this.balanceId, digitalTwinsCreatedTime: this.createdTime, digitalTwinsInventory: this.inventory.map(item => item.toJson()), gtin: this.gtin, lotCode: this.lotCode }, (this.payment && { payment: this.payment.toJson() }));
    }
    createEmpty() {
        return new DigitalTwins(this.name, 0, this.createdTime, this.inventory, this.balanceId, this.gtin, this.lotCode);
    }
    isInventorySameMagnitude() {
        const magnitudes = this.inventory.map(mb => {
            return mb.magnitude;
        });
        return magnitudes.every(mag => magnitudes[0].equals(mag));
    }
}
