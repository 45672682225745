import * as Ably from "ably";
import {apiService, facilityService, userService} from '@/main';
import {Transaction} from "@empower-platform/tracking-frontend-shared-types-js/build/transactions";

export default class MessageService {
    notificationEndpoint = process.env.VUE_APP_FACILITY_API_BASE_URL + '/api/v1/facility';
    ablyClient;
    channels = [];
    store;

    constructor(store) {
        this.store = store;
    }

    async connectAblyClient() {
        await this.getAblyClient();
    }

    async closeAblyClient() {
        if (this.ablyClient) {
            await this.ablyClient.connection.close();
            this.ablyClient = null;
        }
    }

    isConnected() {
        return !!this.ablyClient;
    }

    async getAblyClient() {
        if (!this.ablyClient || !this.ablyClient.isConnected) {
            this.ablyClient = await new Ably.Realtime({
                closeOnUnload: true,
                authCallback: async (tokenParams, callback) => {
                  let tokenRequest;
                  try {
                    tokenRequest = await apiService.call('GET', `${this.notificationEndpoint}/facilities/${facilityService.chosenFacilityId}/token-request`)
                  } catch (err) {
                    callback(err, null);
                    return;
                  }
                  callback(null, tokenRequest);
                },
                authMethod: 'GET',
            });
            await this.ablyClient.connection.on('connected', async () => {
                const facilityChannel = await this.ablyClient.channels.get(facilityService.chosenFacilityId);
                this.subscribeToFacilityChannel(facilityChannel);
                const organizationChannel = await this.ablyClient.channels.get(userService.organizationId);
                this.subscribeToOrganizationChannel(organizationChannel);
            });
        }
    }

    subscribeToFacilityChannel(channel) {
        channel.subscribe(message => {
            const parsedMsg = JSON.parse(message.data)
            const transaction =  Transaction.fromApiResponse(parsedMsg.transaction, this.store.getters.preferredUnits)
            switch (message.name) {
                case 'delivery.deliverer.initiated':
                    this.store.dispatch('toSenderTxInitiated', transaction)
                    break
                case 'delivery.recipient.initiated':
                    this.store.dispatch('toRecipientTxInitiated', transaction);
                    break;
                case 'delivery.deliverer.accept':
                    this.store.dispatch('toSenderTxAccepted', transaction);
                    break;
                case 'delivery.recipient.accept':
                    this.store.dispatch('toRecipientTxAccepted', transaction);
                    break
                case 'delivery.deliverer.reject':
                    this.store.dispatch('toSenderTxRejected', transaction);
                    break;
                case 'delivery.recipient.reject':
                    this.store.dispatch('toRecipientTxRejected', transaction);
                    break;
                case 'delivery.deliverer.cancelled':
                    this.store.dispatch('toSenderTxCancelled', transaction);
                    break;
                case 'delivery.recipient.cancelled':
                    this.store.dispatch('toRecipientTxCancelled', transaction)
                    break;
                case 'delivery.deliverer.unconfirmed':
                    this.store.dispatch('onePartyDeliveryUnconfirmed', transaction);
                    break;
                case 'delivery.recipient.unconfirmed':
                    this.store.dispatch('onePartyReceptionUnconfirmed', transaction);
                    break;
                case 'process':
                    this.store.dispatch('onePartyProcessCompleted', transaction)
                    break;
                case 'delivery.deliverer.changes-requested':
                    this.store.dispatch('toSenderChangesRequested', transaction);
                    break;
                case 'delivery.recipient.changes-requested':
                    this.store.dispatch('toRecipientChangesRequested', transaction);
                    break
                case 'delivery.deliverer.changes-cancelled':
                    this.store.dispatch('toSenderChangesCancelled', transaction);
                    break;
                case 'delivery.recipient.changes-cancelled':
                    this.store.dispatch('toRecipientChangesCancelled', transaction);
                    break;
                case 'delivery.recipient.changes-accepted':
                    this.store.dispatch('toRecipientChangesAccepted', transaction);
                    break;
                case 'delivery.deliverer.changes-accepted':
                    this.store.dispatch('toSenderChangesAccepted', transaction);
                    break;
                case 'delivery.recipient.changes-rejected':
                    this.store.dispatch('toRecipientChangesRejected', transaction);
                    break;
                case 'delivery.deliverer.changes-rejected':
                    this.store.dispatch('toSenderChangesRejected', transaction);
                    break;

                default:
                    console.error('unexpected message received from facility channel: ' + message.name);
                    break;
            }
        })
    }

    subscribeToOrganizationChannel(channel) {
        channel.subscribe(message => {
            const parsedMsg = JSON.parse(message.data)
            const transaction = Transaction.fromApiResponse(parsedMsg.transaction, this.store.getters.preferredUnits)
            const delivererFacilityId = transaction.delivery.facilityId;
            if (facilityService.chosenFacilityId === delivererFacilityId) {
                return;
            }
            switch (message.name) {
                case 'delivery.recipient.initiated':
                    this.store.dispatch('toRecipientTxInitiated', transaction);
                    break;
                case 'delivery.recipient.cancelled':
                    this.store.dispatch('toRecipientTxCancelled', transaction)
                    break;
                default:
                    console.error('Unexpected message received from organization channel: ' + message.name, message);
            }
        })
    }
}
