import cloneDeep from "lodash.clonedeep"

/**
 * This store handles messages from Ably.
 * Its primary responsibilities include:
 * 1. Updating Transactions with new transaction information.
 * 2. Updating Inventories as needed based on new transaction information
 * 3. Adding/Updating history entries as needed based on new transaction information.
 * 4. Dispatching Notifications to the client.
 */

export const initialMessageState = {
    notification: {
        showIt: false,
        transaction: null
    },
}

function commitCommonUpdates(commit, transaction) {
    commit('updateTransactionRequest', transaction)
    commit('addHistoryDeliveryRequest', transaction)
}

export const messageStore = {
    state: {
        ...cloneDeep(initialMessageState)
    },
    actions: {
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderTxInitiated({commit}, transaction) {
            const inventoryItems = transaction.inventoryItemsForSide
            if(!transaction.containsDigitalTwin()) {
                commit('decreaseInventoryMassBalance', inventoryItems)
            } else {
                commit('decreaseInventoryDigitalTwin', inventoryItems)
            }
            commitCommonUpdates(commit, transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientTxInitiated({commit}, transaction) {
            commitCommonUpdates(commit, transaction)
            commit('showNotification', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderTxAccepted({commit}, transaction) {
            // if it is a remote delivery, the inventory has already been decreased.
            if (transaction.isOnsiteDeliveryInDelivererContext()) {
                const inventoryItems = transaction.inventoryItemsForSide
                if(!transaction.containsDigitalTwin()) {
                    commit('decreaseInventoryMassBalance', inventoryItems)
                } else {
                    commit('decreaseInventoryDigitalTwin', inventoryItems)
                }
                commit('setTransaction', transaction)
            }
            commitCommonUpdates(commit, transaction)
            // do not show notification for onsite accept, the onsite deliv screen already handles this
            if (transaction.isRemoteDeliveryInDelivererContext()) {
                commit('showNotification', transaction)
            }
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientTxAccepted({commit}, transaction) {
            const inventoryItems = transaction.inventoryItemsForSide
            if(!transaction.containsDigitalTwin()) {
                commit('increaseInventoryMassBalance', inventoryItems)
            } else {
                commit('increaseInventoryDigitalTwin', inventoryItems)
            }
            commitCommonUpdates(commit, transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderTxRejected({commit}, transaction) {
            if (transaction.isRemoteDeliveryInDelivererContext()) {
                const inventoryItems = transaction.inventoryItemsForSide
                if(!transaction.containsDigitalTwin()) {
                    commit('increaseInventoryMassBalance', inventoryItems)
                } else {
                    commit('increaseInventoryDigitalTwin', inventoryItems)
                }
            }
            commitCommonUpdates(commit, transaction)
            commit('showNotification', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientTxRejected({commit}, transaction) {
            commitCommonUpdates(commit, transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderTxCancelled({commit}, transaction) {
            const inventoryItems = transaction.inventoryItemsForSide
            if(!transaction.containsDigitalTwin()) {
                commit('increaseInventoryMassBalance', inventoryItems)
            } else {
                commit('increaseInventoryDigitalTwin', inventoryItems)
            }
            commitCommonUpdates(commit, transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientTxCancelled({commit}, transaction) {
            commitCommonUpdates(commit, transaction)
            commit('showNotification', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        onePartyDeliveryUnconfirmed({commit}, transaction) {
            const inventoryItems = transaction.inventoryItemsForSide
            if(!transaction.containsDigitalTwin()) {
                commit('decreaseInventoryMassBalance', inventoryItems)
            } else {
                commit('decreaseInventoryDigitalTwin', inventoryItems)
            }
            commit('addHistoryEntry', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        onePartyReceptionUnconfirmed({commit}, transaction) {
            const inventoryItems = transaction.inventoryItemsForSide
            if(!transaction.containsDigitalTwin()) {
                commit('increaseInventoryMassBalance', inventoryItems)
            } // no else here, you can't receive digital twins unconfirmed
            commit('addHistoryEntry', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        onePartyProcessCompleted({commit}, transaction) {
            const inputs = transaction.getInput()
            commit('decreaseInventoryMassBalance', inputs)
            const inventoryItems = transaction.getOutput()
            if(!transaction.containsDigitalTwin()) {
                commit('increaseInventoryMassBalance', inventoryItems)
            }  else {
                commit('increaseInventoryDigitalTwin', inventoryItems)
            }
            commit('addHistoryEntry', transaction)
        },
        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderChangesRequested({commit}, transaction) {
            commit('updateTransactionRequest', transaction)
            commit('addHistoryEntry', transaction)
            commit('showNotification', transaction)
        },

        /**
         * @param commit
         * @param {Transaction} transaction
         */
        toRecipientChangesRequested({commit}, transaction) {
            commitCommonUpdates(commit, transaction);
        },

        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toSenderChangesCancelled({commit}, transaction) {
            commit('updateTransactionRequest', transaction)
            commit('addHistoryEntry', transaction)
            commit('showNotification', transaction)
        },

        /**
         * @param commit
         * @param {Transaction} transaction
         */
        toRecipientChangesCancelled({commit}, transaction) {
            commitCommonUpdates(commit, transaction);
        },

        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientChangesRejected({commit}, transaction) {
            commit('updateTransactionRequest', transaction)
            commit('addHistoryEntry', transaction)
            commit('showNotification', transaction)
        },

        /**
         * @param commit
         * @param {Transaction} transaction
         */
        toSenderChangesRejected({commit}, transaction) {
            commitCommonUpdates(commit, transaction);
        },

        /**
         * @param commit // a vuex commit
         * @param {Transaction} transaction
         */
        toRecipientChangesAccepted({commit}, transaction) {
            commit('updateTransactionRequest', transaction)
            commit('addHistoryEntry', transaction)
            commit('showNotification', transaction)
        },
        /**
         * @param commit
         * @param {Transaction} transaction
         */
        toSenderChangesAccepted({commit}, transaction) {
            commitCommonUpdates(commit, transaction);
        }

    },
    mutations: {
        showNotification(state, transaction) {
            state.notification.showIt = true;
            state.notification.transaction = transaction;
        },
        closeNotification(state) {
            state.notification.showIt = false;
            state.notification.transaction = null;
        },
        /** Sometimes we don't want to notify the user, but still need the transaction set (Onsite Delivery accept) **/
        setTransaction(state, transaction) {
            state.notification.transaction = transaction
        },
        clearTransaction(state) {
            state.notification.transaction = null
        }
    }
}
